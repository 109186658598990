import { Component, Input } from '@angular/core';
import { MembershipTransactionCustomer } from 'src/app/shared/models/transactions/membership-transaction.model';
import { ReservationTransactionCustomer } from 'src/app/shared/models/transactions/reservation-transaction.model';
import { BuyerTypeInfo, SaleTransactionCustomer } from 'src/app/shared/models/transactions/sale-transaction.model';

@Component({
  selector: 'app-customer-card',
  templateUrl: './customer-card.component.html',
  styleUrl: './customer-card.component.css'
})
export class CustomerCardComponent {

  @Input()  customer!: SaleTransactionCustomer | ReservationTransactionCustomer | MembershipTransactionCustomer;
  @Input()  type!:     string;

  protected isOpen:    boolean = true;

  protected get saleBuyerType(): BuyerTypeInfo[] | null {
    if(this.type === 'sale' || this.type === 'package' || this.type === 'exchange' || this.type === 'reservation'){
      const customer = this.customer as SaleTransactionCustomer;
      return customer.buyer_type_info
    }else{
      return null;
    }
  }

  protected get reservationBuyerType(): ReservationTransactionCustomer | null {
    if(this.type === 'reservation'){
      const customer = this.customer as ReservationTransactionCustomer;
      return customer;
    }else{
      return null;
    }
  }

  protected get membershipBuyerType(): MembershipTransactionCustomer | null {
    if(this.type === 'membership'){
      const customer = this.customer as MembershipTransactionCustomer;
      return customer;
    }else{
      return null;
    }
  }

  public toggleVisibility(): void {
    this.isOpen = !this.isOpen;
  }

}
