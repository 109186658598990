import { Component , Input, inject} from '@angular/core';
import { Location } from '@angular/common'
/**
 * ### Back Button
 * El funcionamiento de este componente es sencillo, usando Location de Angular, nos lleva hacia atrás en la vista en la que estuvieramos.
 */
@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css']
})
export class BackButtonComponent {

  private  location: Location = inject(Location);

  /**
   * Devuelve a la página anterior absoluta utilizando location de Angular.
   */
  protected goBack(): void {
    this.location.back();
  }

}
