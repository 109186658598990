<div class="h-full bg-white/80 lg:bg-white flex flex-col relative overflow-hidden" *ngIf="transactions">
    
    <!-- BACK BUTTON -->
    <app-back-button>
       <app-checkout-timer 
        [date]="masterSale.tdc_expiration_time"
        (expire)="cancelTransaction()"/>
    </app-back-button>
    
    <!-- MAIN COMPONENT -->
    <div #mainView *ngIf="transactions.length" class="h-full flex flex-col lg:flex-row lg:mx-8 lg:gap-x-8 overflow-hidden">
    
        <!-- TICKETS -->
        <div class="lg:w-4/12">

            <!-- TICKETS MOBILE BUTTON -->
            <div class="flex py-7 px-24 lg:hidden">
                <button class="button-secondary font-medium">
                    {{ticketQuantity}}
                </button>
            </div>

            <!-- TICKET QUANTITY & ADD EVENT DESKTOP -->
            <div class="hidden lg:flex justify-between">
                <p class="text-xl text-secondary font-bold tracking-tight">
                    {{ticketQuantity}}
                </p>
                <button class="button-secondary w-fit px-6 py-0.5 text-sm flex items-center font-medium" (click)="addTransaction()">
                    Add more events <span class="text-lg ml-1">+</span>
                </button>
            </div>
        
            <!-- DESKTOP TICKETS -->
            <div class="hidden mt-3 max-xl:mb-32 gap-y-4 lg:flex flex-col overflow-y-auto lg:max-h-[80%] max-xl:max-h-[90%]: py-2">
                <app-checkout-ticket *ngFor="let sale of transactions; index as i" [saleData]="sale" [index]="i" [type]="type"/>
            </div>
        </div> 
            
        <!-- PAYMENT METHOD AND INFO -->
        <div class="lg:w-8/12 lg:flex flex-col items-center lg:justify-between md:overflow-y-auto">
            <div class="bg-white rounded-[15px_15px_0_0] p-5 min-[1600px]:mt-28" [ngClass]="{'min-[1600px]:mt-28': type != 'membership'}">
                
                <!-- TEXT -->
                <div>
                    <h4 class="text-xl lg:text-2xl 2xl:text-3xl xl:w-8/12 text-main font-medium">
                        {{templateData.title}}
                    </h4>

                    <p class="my-5 font-medium text-darkgray font-main">
                        {{templateData.text}}
                    </p>
                </div>

                <hr>

                <!-- DELIVERY METHOD AND SUMMARY TABLE -->
                <div class="flex flex-col lg:flex-row mt-5">
                   
                    <!-- DELIVERY -->
                    <div class="w-full lg:w-1/2">
                        <app-checkout-delivery 
                            [email]="user.email" 
                            [form]="adressForm" 
                            [methods]="deliveryMethods" 
                            (selected)="selectDeliveryMethod($event)"
                        />
                    </div>

                    <!-- SUMMARY -->
                    <div class="w-full lg:w-1/2">
                        <app-checkout-amount
                         [total]="totalAmount" 
                         [delivery]="deliveryAmount" 
                        />
                    </div>

                </div>  
            </div>
            
            <!-- CONFIRM OR CANCEL TRANSACTION -->
            <div class="w-full flex justify-between p-5 border-t">
                <button (click)="cancelTransaction()"  class="button-outline w-fit border-secondary text-secondary font-medium px-4">Cancel transaction</button>
                <button (click)="launchModal()" [disabled]="isAdressFormFull" class="button w-fit px-4">Checkout</button>
            </div>
        </div>

    </div>

    <!-- QUICKVIEW (Mobile Only) -->
    <!-- <div #quickView class="absolute bottom-0 left-0 w-full h-auto z-30 bg-white ease-in duration-300 transform origin-center lg:hidden" [ngClass]="quickview ? 'translate-y-0':'translate-y-[100vh]'">
        <div class="flex flex-col p-5">
           
            <p class="text-2xl text-secondary font-bold tracking-tight my-5">
                {{ticketQuantity}}
            </p>


            <div class="my-3">
                <app-checkout-ticket *ngFor="let sale of salesTransactions; index as i" [saleData]="sale" [index]="i" />
            </div>

            <div class="flex flex-col items-center justify-center gap-y-3 mt-5">
                <button class="button-secondary w-fit px-4" (click)="returnToHome()">
                    Add More Events 
                    <span>
                        +
                    </span>
                </button>
    
                <button class="font-main text-darkgray w-fit" (click)="toggleQuickview()">
                    Close
                </button>
            </div>

        </div>
    </div> -->

    <swal #checkoutModal>
        <ng-container *swalPortal="swalTarget.content">
            <div #adyen></div>
        </ng-container>
    </swal>
</div>

