import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from './summary.component';
import { SummarySuccessComponent } from './summary-success/summary-success.component';
import { SummaryErrorComponent } from './summary-error/summary-error.component';
import { TimerModule } from '../checkout/checkout-timer/timer.module';
import { CheckoutTicketModule } from '../checkout/checkout-ticket/checkout-ticket.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AdyenModule } from '../adyen/adyen.module';



@NgModule({
  declarations: [
    SummaryComponent,
    SummarySuccessComponent,
    SummaryErrorComponent,
  ],
  imports: [
    AdyenModule,
    CommonModule,
    TimerModule,
    CheckoutTicketModule,
    SweetAlert2Module,
  ],
  exports: [
    SummaryComponent
  ]
})
export class SummaryModule { }
