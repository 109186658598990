<div [ngClass]="{'bg-gradient-to-t': isEven, 'bg-gradient-to-b': !isEven}" class="from-main via-main/90 to-main/85 rounded-md p-3 flex flex-col">

    <!-- Title -->
    <div class="p-3 text-white" *ngIf="saleData.event_info">
        <h3 class="text-xl font-medium">
            {{saleData.event_info.name}}
        </h3>
        <p>
            {{saleData.event_info.venue}} {{saleData.event_info.date | date:'M/d/yy h:mm a'}}
        </p>
    </div>

    <!-- Tickets -->
    <div class="flex flex-col gap-y-3">
       <app-customer-card *ngFor="let customer of customers" [customer]="customer" [type]="type"/>
    </div>
    
    <!-- View -->
    <div *ngIf="show3dView" class="mt-3">
        <dvm-viewer3d [initOptions]="viewer3dInitOptions" [loadOptions]="viewer3dLoadOptions" #viewer></dvm-viewer3d>
    </div>

</div>