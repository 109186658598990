import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutService } from 'src/app/core/services/checkout.service';
import { MembershipTransactionService } from 'src/app/core/services/override/checkout/membership-transaction.service';
import { PackageTransactionService } from 'src/app/core/services/override/checkout/package-transaction.service';
import { SaleTransactionService } from 'src/app/core/services/override/checkout/sale-transaction.service';
import { MembershipTransaction } from 'src/app/shared/models/transactions/membership-transaction.model';
import { PackageTransaction } from 'src/app/shared/models/transactions/package-transaction.model';
import { ReservationTransaction } from 'src/app/shared/models/transactions/reservation-transaction.model';
import { SaleTransaction } from 'src/app/shared/models/transactions/sale-transaction.model';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.css'
})
export class SummaryComponent implements OnInit {

  constructor(
    private checkoutService: CheckoutService<SaleTransaction | MembershipTransaction | PackageTransaction | ReservationTransaction>
  ) {}

  private activatedRoute: ActivatedRoute  = inject(ActivatedRoute);
  private router:         Router          = inject(Router);


  protected status!:          'success' | 'error';
  protected type!:             string;
  protected transactionId!:    number;
  protected transaction!:      SaleTransaction | MembershipTransaction | PackageTransaction | ReservationTransaction | ReservationTransaction;

  ngOnInit(): void {
    this.startComponent();
  }

  public returnToHome(): void {
    this.router.navigate(['']);
  }

  private startComponent(): void {

    // STATUS
    this.activatedRoute.params.subscribe(param =>{
      this.status = param['status'];
    })

    // TYPE AND TRANSACTION ID
    this.activatedRoute.queryParams.subscribe(queryParam =>{
      this.type          = queryParam['type'];
      this.transactionId = queryParam['transaction']
    })

    // CHECK IF TYPE AND TRANSACTION ID ARE SET
    if(!this.type || !this.transactionId) {
      this.returnToHome();
    }

    // SET TRANSACTION DATA
    switch(this.type){
      case 'sale':
        // SALE TRANSACTION
        this.checkoutService.getTransaction(this.transactionId).subscribe(response => this.setData(response as SaleTransaction));
      break;
      
      case 'membership':
        // MEMBERSHIP TRANSACTION
        this.checkoutService.getTransaction(this.transactionId).subscribe(response => this.setData(response as MembershipTransaction)); 
      break;

      case 'package':
        //PACKAGE TRANSACTION
        this.checkoutService.getTransaction(this.transactionId).subscribe(response => this.setData(response as PackageTransaction));
      break;

      case 'reservation':
        //RESERVATION TRANSACTION
        this.checkoutService.getTransaction(this.transactionId).subscribe(response => this.setData(response as ReservationTransaction));
      break;

      default:
        this.returnToHome();
    }
   
  }

  private setData(response: SaleTransaction | MembershipTransaction | PackageTransaction ){
    if(!response){
      this.returnToHome();
      return;
    }
    this.transaction = response;
  }
}
