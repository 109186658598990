import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * ### Footer
 * El footer es un componente dinámico que se usa en varios apartados de la web. Por ello, aunque su comportamiento es el de 
 * estar anclado en la parte inferior y dejarnos continuar con el flujo, en cada apartado tendrá una logica distinta para habilitar el botón para continuar, 
 * así como del texto que se muestra en el componente. En Movil se esconde automaticamente utilizando showFooter.
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  @Input()  textDetail!:   string;            // Text Detail of Footer
  @Input()  buttonText!:   string;            // Text of the footer button
  @Input()  showFooter:    boolean = true;    // Show or hide footer on MOBILE
  @Input()  disableButton: boolean = false;   // Enable or disable the footer button

  @Output() footerEvent: EventEmitter<any> = new EventEmitter<void>();

  /**
   * Emisor de eventos del Footer.
   * Normalmente se le da uso en el componente padre que aloje el footer.
   */
  protected buttonClick(): void{
    this.footerEvent.emit();
  }
}
