import { Component, EventEmitter, Input, OnDestroy, OnInit, inject, Output, isDevMode } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { InformationModalParams } from 'src/app/shared/models/modal.model';

@Component({
  selector: 'app-checkout-timer',
  templateUrl: './checkout-timer.component.html',
  styleUrl: './checkout-timer.component.css'
})
export class CheckoutTimerComponent implements OnInit, OnDestroy {

  @Input()  date!:  Date;
  @Output() expire: EventEmitter<void> = new EventEmitter<void>();

  private   modal:   ModalService = inject(ModalService);

  private   timer$:     Subject<string> = new Subject<string>()
  private   timer!:     string;
  private   interval!:  number;

  public get timerValue() {
    return this.timer;
  }

  ngOnInit(): void {
    this.initTimer();
  }

  ngOnDestroy(): void {
    this.closeTimer();
  }

  private initTimer(): void {
    // Set Subject
    this.timer$.subscribe(timer => this.timer = timer);
    // Start Interval
    this.setInterval();
  }

  private setInterval(): void {
    this.interval = window.setInterval(()=>{
      this.setTimer();
    } , 1000)
  }

  private setTimer(): void {
    
    let now:       number = new Date().getTime();
    let countdown: number = new Date(this.date).getTime();
    let diference: number = (countdown - now) / 1000;
 
    if(diference <= 0){
      diference = 0;
      clearInterval(this.interval);
      this.launchModal();
    }
    
    const timeString = this.timeToString(diference);
    this.timer$.next(timeString);
  }

  private timeToString(time:number): string {
    // Days
    const days = Math.floor(time / 86400);
    time -= days * 86400;
    // Hours
    const hoursNum = Math.floor(time / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    time -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(time / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    time -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(time % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    // return days + 'd ' + hours + ':' + minutes + ':' + seconds;
    return minutes + ':' + seconds;
  }

  private launchModal():void {

    const modalParams: InformationModalParams = {
      title:   'Transaction Expired',
      content: 'Your transaction has expired. Please, click the button below to return to the home page.',
      onConfirm: () => this.expire.emit()
    }

    this.modal.createInformationModal(modalParams);
    
  }

  private closeTimer(){
    clearInterval(this.interval);
    this.timer$.unsubscribe()
  }

}
