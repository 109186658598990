<div class="flex flex-col h-full">
    <!-- TITLE -->
    <section>

        <div class="container mx-auto py-5">

            <h2 class="font-semibold text-secondary text-base md:text-lg lg:text-xl text-center">
                There was an error processing your transaction
            </h2>
            <p class="text-darkgray text-xs text-center my-2 md:text-sm">
                You can either retry the payment or restart your purchase
            </p>
            
        </div>
    
    </section>
    
    <!-- BUTTONS -->
    <section class="bg-white flex-grow">
        
        <div class="flex justify-center mt-5 opacity-80 lg:justify-end lg:mx-10">
            <app-checkout-timer 
                [date]="saleTransaction.tdc_expiration_time" 
                (expire)="returnToHome()" 
            />
        </div>

        <div class="mt-3 container mx-auto flex flex-col items-center">
            
            <button class="button-secondary my-5 font-semibold w-5/12 lg:w-3/12" (click)="restartTransaction()">
                Restart Transaction
            </button>

            <button class="button-outline my-5 font-semibold w-5/12 lg:w-3/12" (click)="returnToHome()">
                Back to Home
            </button>

        </div>

    </section>

    <swal #summaryModal>
        <ng-container *swalPortal="swalTarget.content">
            <div #adyen></div>
        </ng-container>
    </swal>
</div>