<div class="relative h-11 flex items-center">
    <div class="w-full flex justify-between bg-white dark:bg-gray-900 dark:lg:bg-transparent dark:border-gray-800 fixed lg:static z-20 p-3 border border-t shadow-md shadow-secondary/10 lg:mx-6 lg:bg-transparent lg:border-none lg:p-0 lg:shadow-none">
        <button aria-label="Back Button" role="button" tabindex="1" (click)="goBack()"
                class="text-main dark:text-secondary font-main flex items-center font-medium text-sm hover:scale-95 transition-transform ease-in lg:text-base focus:outline-main">
            <i>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 lg:w-5 lg:h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                </svg>
            </i>
            <span class="ml-1">
                Go Back
            </span>
        </button>

          <!-- Optional Content (e.g Timer in Checkout | SAS Toggler) -->
        <ng-content></ng-content>
    </div>
</div>
