import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-checkout-amount',
  templateUrl: './checkout-amount.component.html',
  styleUrl: './checkout-amount.component.css'
})
export class CheckoutAmountComponent {

  @Input() total!:    number;
  @Input() delivery!: number;

  public get totalAmount(): number {
    return this.total += this.delivery;
  }

}
