import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutTicketComponent } from './checkout-ticket.component';
import { CustomerCardComponent } from './customer-card/customer-card.component';
import { DvmModule } from '@3ddv/ngx-dvm-internal';


@NgModule({
  declarations: [
    CheckoutTicketComponent,
    CustomerCardComponent
  ],
  imports: [
    CommonModule,
    DvmModule
  ],
  exports: [
    CheckoutTicketComponent
  ]
})
export class CheckoutTicketModule { }
