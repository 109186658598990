import { Viewer3dLoadView3dOptions } from '@3ddv/dvm-internal';
import { Viewer3dComponent, Viewer3dInitOptions } from '@3ddv/ngx-dvm-internal';
import { Component, Input, AfterViewInit, ViewChild, inject, OnInit } from '@angular/core';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { ExchangeTransaction } from 'src/app/shared/models/transactions/exchange-transaction.model';
import { MembershipTransaction, MembershipTransactionCustomer } from 'src/app/shared/models/transactions/membership-transaction.model';
import { PackageTransaction } from 'src/app/shared/models/transactions/package-transaction.model';
import { ReservationTransaction, ReservationTransactionCustomer } from 'src/app/shared/models/transactions/reservation-transaction.model';
import { SaleTransaction, SaleTransactionCustomer, Seat } from 'src/app/shared/models/transactions/sale-transaction.model';
import { APP_CONFIG } from 'src/configuration/configuration';

@Component({
  selector: 'app-checkout-ticket',
  templateUrl: './checkout-ticket.component.html',
  styleUrl: './checkout-ticket.component.css'
})
export class CheckoutTicketComponent implements OnInit, AfterViewInit {
  
  @ViewChild('viewer', {static:false})
  viewer!:   Viewer3dComponent;
  
  @Input() 
  saleData!: SaleTransaction | MembershipTransaction | PackageTransaction | ExchangeTransaction | ReservationTransaction;

  @Input()
  type!:     string;

  @Input()
  index!:    number; 
  
  private   configuration: Configuration = inject(APP_CONFIG);

  protected show3dView!:          boolean;            
  protected viewer3dInitOptions:  Viewer3dInitOptions = {};
  protected viewer3dLoadOptions:  Viewer3dLoadView3dOptions = { venue_id: '', view_id: '' };
  
  protected get customers(): (SaleTransactionCustomer | ReservationTransactionCustomer | MembershipTransactionCustomer)[] {

    switch(this.type){
      case 'membership':
        const membership = this.saleData as MembershipTransaction;
        return membership.membership_transaction_customers;

      case 'package':
        const packages = this.saleData as PackageTransaction;
        return packages.sale_transaction_customers

      case 'reservation':
        const reservation = this.saleData as ReservationTransaction;
        return reservation.reservation_transaction_customers;

      case 'exchange':
        const exchange = this.saleData as ExchangeTransaction;
        return exchange.sale_transaction_customers;
      
      default:
        const sale = this.saleData as SaleTransaction;
        return sale.sale_transaction_customers;
    }

  }

  protected get seatData(): Seat | null {
    if(this.type !== 'membership'){
      const sale = this.saleData as SaleTransaction;
      return sale.sale_transaction_customers[0].buyer_type_info[0].seats[0];
    }else{
      return null;
    }
  }

  protected get isEven(): boolean {
    return this.index % 2 === 0;
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngAfterViewInit(): void {
    if (this.show3dView) {
      this.set3dView();
    }
  }

  /**
   * Instancia el componente.
   * Declara si se muestra la vista 3d o no. 
   */
  private initComponent(): void {
    this.show3dView = this.type === 'membership' ?  false : true;
  }
  
  /**
   * ### Carga la vista 3d.
   * 
   * Este método se carga si el type no es membership. Sin embargo, por la tipologia de los datos,
   * necesitamos hacer la comprobación de si el type es reservation ya que los datos de asiento los guarda 
   * en otra propiedad. 
   * 
   * El método rellena los datos de la propiedad viewer3dLoadOptions con los datos necesarios para cargar la vista 3d y 
   * posteriormente inicializa el componente viewer3d.
   * 
   */
  private set3dView(): void {

    if(this.type !== 'reservation'){
      this.viewer3dLoadOptions = {
        venue_id: this.configuration.venue[this.saleData.event_info.venue].venueID,
        view_id:  this.seatData!.tdc_section_id.toString(),
      }
    }else{
      
      const reservationOrder = this.customers[0] as ReservationTransactionCustomer;

      if(!reservationOrder.orders_claimed[0].pending_items[0].seats[0].tdc_section_id){
        this.show3dView = false;
        return;
      }

      this.viewer3dLoadOptions = {
        venue_id: this.configuration.venue[reservationOrder.orders_claimed[0].pending_items[0].venue].venueID,
        view_id:  reservationOrder.orders_claimed[0].pending_items[0].seats[0].tdc_section_id.toString(),
      };

    }

    this.viewer.service.initialize().subscribe(()=>{
      this.viewer.service.loadView3d(this.viewer3dLoadOptions).subscribe({
        error: (e) => this.show3dView = false
      })
    })
  
  }

}
