import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService } from '../../checkout.service';
import { MembershipTransaction } from 'src/app/shared/models/transactions/membership-transaction.model';

@Injectable({
  providedIn: 'root'
})
export class MembershipTransactionService extends CheckoutService<MembershipTransaction> {

  constructor(
    router: Router,
    http:   HttpClient,
  ) { 
    super(router, http)
  }

  protected override endpoint: string = '/membership_transaction/';

}
