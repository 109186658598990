<div class="w-full">
  <p class="text-lg text-main font-medium">Summary</p>
  <div class="p-5 shadow-lg rounded-lg mt-3">
    <table class="font-main">
      <tr>
        <th class="text-left font-normal text-darkgray">Subtotal:</th>
        <td class="pl-7 font-medium text-gray-600">
          {{ total | currency : "GBP" }}
        </td>
      </tr>
      <tr>
        <th class="text-left font-normal text-darkgray">Delivery:</th>
        <td class="pl-7 font-medium text-gray-600">
          {{ delivery | currency : "GBP" }}
        </td>
      </tr>
      <tr>
        <th class="text-left font-bold text-main">Total:</th>
        <td class="pl-7 font-bold text-main">
          {{ totalAmount | currency : "GBP" }}
        </td>
      </tr>
    </table>
  </div>
</div>
