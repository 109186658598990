import { Injectable } from '@angular/core';
import AdyenCheckout from '@adyen/adyen-web';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AdyenService {
  constructor(private router: Router, private http: HttpClient) {
  }

  // https://docs.adyen.com/online-payments/build-your-integration?platform=Web&integration=Drop-in&version=5.43.0&tab=npm_recommended__1#initialize-the-payment-session
  public async startPaymentSession(sessionId: string, sessionData: string, returnUrl: string) {
    const configuration = {
      environment: environment.adyen.environment, // Change to 'live' for the live environment.
      clientKey: environment.adyen.clientKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      analytics: {
        enabled: true, // Set to false to not send analytics data to Adyen.
      },
      session: {
        id: sessionId, // Unique identifier for the payment session.
        sessionData, // The payment session data.
      },
      onPaymentCompleted: (result: any, component: any) => {
        this.onPaymentCompleted(result, component, returnUrl);  
      },
      onError: (error: any, component: any) => {
        // if error we have to redirect to error url
        console.error(error.name, error.message, error.stack, component);
        this.router.navigateByUrl(returnUrl.split(location.hostname)[1].replace('success', 'error'));
      },
      // // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // // For example, this is 3D Secure configuration for cards:
      // paymentMethodsConfiguration: {
      //   card: {
      //     hasHolderName: true,
      //     holderNameRequired: true,
      //     billingAddressRequired: true,
      //   },
      // },
    };
   
    // Create an instance of AdyenCheckout using the configuration object.
    const checkout = await AdyenCheckout(configuration);
    // Create an instance of Drop-in and mount it to the container you created.
    const dropinComponent = checkout.create('dropin', {
      setStatusAutomatically: false,
      // https://docs.adyen.com/online-payments/build-your-integration/?platform=Web&integration=Drop-in&version=5.49.1#drop-in-configuration
      // we need that in order to remove payment methods stored
      showRemovePaymentMethodButton: true,
      onDisableStoredPaymentMethod: (storedPaymentMethodId, resolve, reject) => {
        const endpoint = `${environment.apiUrl}/adyen/disable_payment_method/${storedPaymentMethodId}/`;
        this.http.post(endpoint, {}).subscribe({
          next: (data) => resolve(),
          error: (err) => reject(),
        });
      },
    }).mount('#dropin-container');
  }

  // https://docs.adyen.com/online-payments/build-your-integration?platform=Web&integration=Drop-in&version=5.43.0#handle-the-redirect
  public async adyenHandleRedirect(sessionId: string, redirectResult: string) {
    const configuration = {
      environment: environment.adyen.environment, // Change to 'live' for the live environment.
      clientKey: environment.adyen.clientKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      analytics: {
        enabled: true, // Set to false to not send analytics data to Adyen.
      },
      session: {
        id: sessionId, // Unique identifier for the payment session.
      },
      onPaymentCompleted: (result: any, component: any) => {
        this.onHandleRedirectCompleted(result, component);
      },
      onError: (error: any, component: any) => {
        component.setStatus('error', { message: 'Your payment has not been authorised' });
        // on error, we have to redirect to error url, we are in success url at this time
        console.error(error.name, error.message, error.stack, component);
        this.router.navigateByUrl(location.href.split(location.hostname)[1].replace('success', 'error'));
      },
    };
    // Create an instance of AdyenCheckout to handle the shopper returning to your website.
    // Configure the instance with the sessionId you extracted from the returnUrl.
    const checkout = await AdyenCheckout(configuration);

    // Submit the redirectResult value you extracted from the returnUrl.
    checkout.submitDetails({ details: { redirectResult } });
  }

  // todo better handle navigateByUrl
  private onPaymentCompleted(result: any, component: any, returnUrl: any) {
    // returnUrl is returned by the server in order to not get out of sync, having one return url configured in the
    // server in 3ds cases and another in frontend in normal scenarios
    switch (result.resultCode) {
      case 'Authorised':
        component.setStatus('success', { message: 'Your payment has been authorised' });
        setTimeout(()=>{
          this.router.navigateByUrl(returnUrl.split(location.hostname)[1]);
        },1500)
        break;
      case 'Pending':
      case 'Received':
        component.setStatus('success', { message: 'Your payment has been received' });
        setTimeout(()=>{
          this.router.navigateByUrl(returnUrl.split(location.hostname)[1]);
        },1500)
        break;
      case 'Refused':
        component.setStatus('error', { message: 'Your payment has not been authorised' });
        setTimeout(()=>{
          this.router.navigateByUrl(returnUrl.split(location.hostname)[1].replace('success', 'error'));
        },1500)
        break;
      default:
        component.setStatus('error', { message: 'Your payment has not been authorised' });
        setTimeout(()=>{
          this.router.navigateByUrl(returnUrl.split(location.hostname)[1].replace('success', 'error'));
        },1500)
        break;
    }
  }

  // todo better handle navigateByUrl
  private onHandleRedirectCompleted(result: any, component: any) {
    console.log(result);
    // returnUrl is returned by the server in order to not get out of sync, having one return url configured in the
    // server in 3ds cases and another in frontend in normal scenarios
    switch (result.resultCode) {
      case 'Authorised':
      case 'Pending':
      case 'Received':
        // as we are in the success url we don't have to do nothing but wait the webhook
        console.log('redirection ok');
        break;
      // on error we have to redirect to error url, we are in success url at this time
      case 'Refused':
        this.router.navigateByUrl(location.href.split(location.hostname)[1].replace('success', 'error'));
        break;
      default:
        this.router.navigateByUrl(location.href.split(location.hostname)[1].replace('success', 'error'));
        break;
    }
  }
}
