
<!-- SUCCESS -->
<ng-container *ngIf="status === 'success'  else error">
    
         <app-summary-success 
            [transactionId]="transactionId" 
            [saleTransaction]="transaction" 
            [type]="type"
        />
   
</ng-container>

<!-- ERROR -->
<ng-template #error>
    
    <ng-container *ngIf="transaction">
        <app-summary-error 
            [transactionId]="transactionId" 
            [saleTransaction]="transaction"
            [type]="type"
        />
    </ng-container>
    
</ng-template>

