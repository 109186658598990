<div class="flex" *ngIf="timerValue">
    <p class="font-main font-medium text-darkgray text-sm lg:text-base inline-flex gap-x-1 items-center">
        Your transaction will expire in 
        <i>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>               
        </i>
        <span class="font-medium text-secondary">
            {{timerValue}}
        </span>
    </p>
</div>
