import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService } from '../../checkout.service';
import { PackageTransaction } from 'src/app/shared/models/transactions/package-transaction.model';

@Injectable({
  providedIn: 'root'
})
export class PackageTransactionService extends CheckoutService<PackageTransaction> {

  constructor(
    router: Router,
    http:   HttpClient
  ) {
    super(router,http)
  }
  public override returnTo:    string = 'buy-packages';
  protected override endpoint: string = '/package_transaction/';
}
