<div class="relative">
    <div [ngClass]="{'translate-y-32 lg:transform-none' : !showFooter}" class="inline-block fixed bottom-0 z-10 shadow-inner w-full lg:w-[calc(100%-var(--navbar-width))] p-3 bg-white dark:bg-slate-900 translate-y-0 transition-transform duration-200 ease-in-out">
        <div class="flex justify-between lg:justify-end items-center px-3 mb-2 ">
            <p class="text-darkgray font-bold lg:mr-6">
                {{textDetail}}
            </p>
            <button class="button-secondary w-fit text-sm items-center flex px-5 py-2 font-medium" (click)="buttonClick()" [disabled]="disableButton">
                {{buttonText}}
                <span>
                    <i>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 lg:w-6 lg:h-6">
                            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                        </svg>                      
                    </i>
                </span>
            </button>
        </div>
    </div>
</div>