import { Component, Input, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { AdyenDropInComponent } from '../../adyen/adyen-drop-in/adyen-drop-in.component';
import { SaleTransaction } from 'src/app/shared/models/transactions/sale-transaction.model';
import { SaleTransactionService } from 'src/app/core/services/override/checkout/sale-transaction.service';
import { MembershipTransactionService } from 'src/app/core/services/override/checkout/membership-transaction.service';
import { MembershipTransaction } from 'src/app/shared/models/transactions/membership-transaction.model';
import { PackageTransaction } from 'src/app/shared/models/transactions/package-transaction.model';
import { PackageTransactionService } from 'src/app/core/services/override/checkout/package-transaction.service';

@Component({
  selector: 'app-summary-error',
  templateUrl: './summary-error.component.html',
  styleUrl: './summary-error.component.css'
})
export class SummaryErrorComponent {
  
  @Input()
  transactionId!:  number;

  @Input()
  saleTransaction!: SaleTransaction | MembershipTransaction | PackageTransaction;

  @Input()
  type!:  string;

  @ViewChild('summaryModal')
  modal!: SwalComponent;

  @ViewChild('adyen', {read: ViewContainerRef})
  adyen!: ViewContainerRef;

  private router:       Router                       = inject(Router);
  private sale:         SaleTransactionService       = inject(SaleTransactionService);
  private membership:   MembershipTransactionService = inject(MembershipTransactionService);
  private package:      PackageTransactionService    = inject(PackageTransactionService);
  protected swalTarget: SwalPortalTargets            = inject(SwalPortalTargets);
  
  public returnToHome(): void {
    this.router.navigate(['']);
  }

  public restartTransaction(): void {
    
    switch(this.type){

      case 'sale':
        this.sale.restartTransaction(this.transactionId).subscribe({
          next: (response) => this.launchTransactionModal(response),
          error: () => this.returnToHome(),
        });
      break;

      case 'membership':
        this.membership.restartTransaction(this.transactionId).subscribe({
          next: (response) => this.launchTransactionModal(response),
          error: () => this.returnToHome(),
        });
      break;
      
      case 'package':
        this.package.restartTransaction(this.transactionId).subscribe({
          next: (response) => this.launchTransactionModal(response),
          error: () => this.returnToHome(),
        })
      break;
      
      default:
        this.returnToHome();

    }

  }
    
  private launchTransactionModal(response: any): void {

    const modalConfig: SweetAlertOptions = {
      showConfirmButton: false,
      showCancelButton: true,
      buttonsStyling: false,
      cancelButtonText: 'Close',
      customClass:{
        cancelButton: "button w-fit px-2 button-outline dark:border-secondary dark:text-secondary",
      }
    }
    
    this.modal.swalOptions = modalConfig;

    setTimeout(()=>{
      const component = this.adyen.createComponent(AdyenDropInComponent);
        component.instance.sessionId   = response.session_id;
        component.instance.sessionData = response.session_data;
        component.instance.returnUrl   = response.return_url;
        component.instance.start()
      },100)
      
    this.modal.fire()
  }

}
