import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckoutItem, DeliveryMethod, ExchangeItem, ReservationItem, initCheckout, initTransaction } from 'src/app/shared/models/checkout.model';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService<T> {

  constructor(
    private router: Router,
    private http:   HttpClient
  ) {}

  public    returnTo: string =  '';
  protected endpoint: string =  '';
  
  private get rootUrl(): string {
    return this.endpoint;
  }

  public initCheckout(data: CheckoutItem | ExchangeItem | ReservationItem, param?: string): Observable<initCheckout> {
    const params: any = {};
    
    if(param){
      params['purchase_flow'] = param;
    }

    const queryParams: HttpParams = new HttpParams({fromObject: params});

    return this.http.post<initCheckout>(this.rootUrl, data, {params: queryParams});
  }

  public initTransaction(transactionId: number, data: DeliveryMethod): Observable<initTransaction>{

    const body: DeliveryMethod  = this.setTransactionData(data);

    return this.http.put<initTransaction>(this.rootUrl + transactionId + '/', body);

  }

  public getTransaction(transactionId: number): Observable<T> {
    
    return this.http.get<T>(this.rootUrl + transactionId + '/');

  }

  public restartTransaction(transactionId: number): Observable<initTransaction> {

    return this.http.patch<initTransaction>(this.rootUrl + transactionId + '/',{});

  }

  public cancelTransaction(transactionId: number): Observable<void> {
    return this.http.delete<void>(this.rootUrl + `${transactionId}/`);
  }

  private setTransactionData(data: DeliveryMethod): DeliveryMethod {
    const body: DeliveryMethod = {
      delivery_method_id:     data.delivery_method_id,
      delivery_method_type:   data.delivery_method_type,
      delivery_first_name:    data.delivery_first_name,
      delivery_last_name:     data.delivery_last_name
    };

    if (data.delivery_method_type === 'EXTERNAL_TICKETS_AT_HOME'){

      body['delivery_email'] = data.delivery_email;

    }else if (data.delivery_method_type === 'POSTAL' || data.delivery_method_type === 'POSTAL_TRACKABLE'){
      
      body['delivery_address1']     = data.delivery_address1;
      body['delivery_city']         = data.delivery_city;
      body['delivery_country_code'] = data.delivery_country_code;
      body['delivery_postal_code']  = data.delivery_postal_code;

      if(data.delivery_address2){
        body['delivery_address2']   = data.delivery_address2;
      }

      if(data.delivery_sub_country_code){
        body['delivery_sub_country_code'] = data.delivery_sub_country_code;
      } 

      if(data.delivery_sub_country_name) {
        body['delivery_sub_country_name'] = data.delivery_sub_country_name;
      }

    }

    return body;
  }

}
