import { Injectable } from '@angular/core';
import { CheckoutService } from '../../checkout.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SaleTransaction } from 'src/app/shared/models/transactions/sale-transaction.model';

@Injectable({
  providedIn: 'root'
})
export class SaleTransactionService extends CheckoutService<SaleTransaction> {
  
  constructor(
    router: Router,
    http:   HttpClient,
  ) { 
    super(router, http)
  }

  public override returnTo: string = 'buy-tickets';
  public override endpoint: string = '/sale_transaction/';

}
