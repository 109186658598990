import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponent } from './checkout.component';
import { CheckoutDeliveryComponent } from './checkout-delivery/checkout-delivery.component';
import { CheckoutAmountComponent } from './checkout-amount/checkout-amount.component';
import { BackButtonModule } from 'src/app/shared/components/globals/back-button/back-button.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ReactiveFormsModule } from '@angular/forms';
import { TimerModule } from './checkout-timer/timer.module';
import { DvmModule } from '@3ddv/ngx-dvm-internal';
import { CheckoutTicketModule } from './checkout-ticket/checkout-ticket.module';
import { AdyenModule } from '../adyen/adyen.module';


@NgModule({
  declarations: [
    CheckoutComponent,
    CheckoutDeliveryComponent,
    CheckoutAmountComponent,
  ],
  imports: [
    AdyenModule,
    CommonModule,
    TimerModule,
    CheckoutTicketModule,
    BackButtonModule,
    SweetAlert2Module,
    DvmModule,
    ReactiveFormsModule
  ],
  exports: [
    CheckoutComponent
  ]
})
export class CheckoutModule { }
