import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DeliveryMethodsAvailable } from 'src/app/shared/models/transactions/sale-transaction.model';
import { CheckoutForm } from '../checkout.model';

@Component({
  selector: 'app-checkout-delivery',
  templateUrl: './checkout-delivery.component.html',
  styleUrl: './checkout-delivery.component.css'
})
export class CheckoutDeliveryComponent {

  @Input()  
  email!:    string;

  @Input()  
  methods!: DeliveryMethodsAvailable[];

  @Input()
  form!:    FormGroup<CheckoutForm['controls']>;

  @Output() 
  selected: EventEmitter<number> = new EventEmitter<number>();

  protected postalMode!:     boolean;
  protected selectedMethod!: DeliveryMethodsAvailable;

  public get defaultMethod(): DeliveryMethodsAvailable {
    return this.methods[0];
  }

  ngOnInit(): void {
    this.startComponent();
  }

  public onSelect(index: string): void {
    
    const key: number = parseInt(index);

    this.selectedMethod = this.methods[key];
    this.selected.emit(key);

  }

  private startComponent(): void {
    if( (this.defaultMethod.type === 'POSTAL' || this.defaultMethod.type === 'POSTAL_TRACKABLE') || (this.selectedMethod?.type === 'POSTAL' || this.selectedMethod?.type === 'POSTAL_TRACKABLE') ){
      this.postalMode = true;
    }else{
      this.postalMode = false;
    }
  }

}
